const navigation = document.querySelector(".menu ul");
const menu = document.querySelector("#hamburgermenu");
const body = document.querySelector("body");

console.log(navigation);

menu.addEventListener("click", () => {

	navigation.style.setProperty("--childenNumber", navigation.children.length);


	navigation.classList.toggle("active");
	menu.classList.toggle("active");
	body.classList.toggle("menu-open");
});
